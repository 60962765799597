import React, { useState } from "react";
import Button from "../../../components/customComponents/Button";
import axios from "axios";
import Swal from "sweetalert2";

function MakePayment({ fare, balance, student_id, reload, setReload }) {
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isRecording, setRecording] = useState(false);

  const handleTopUpPayment = (e) => {
    e.preventDefault();
    const data = {
      student_id: student_id,
      amount: amount,
    };

    axios
      .post("/api/v1/payment/record-payment", data)
      .then((res) => {
        if (res?.data?.status === 200 || res?.data?.status === 201) {
          Swal.fire("Success", res?.data?.msg, "success").then();
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err?.response);
        if (
          err.response?.data?.status === 400 ||
          err.response?.status === 422
        ) {
          Swal.fire(
            "Failed",
            err?.response?.data?.error_msg || err?.response?.data?.errors,
            "info"
          );
        } else {
          Swal.fire(
            "Processing Failed",
            "Error Recording Bus Payment. Make sure all fields are filled",
            "error"
          );
        }
      })
      .finally(() => {
        setLoading(false);
        setReload(!reload);
      });
  };

  const handlePayment = (e) => {
    e.preventDefault();
    setRecording(true);
    if (Number(balance) >= Number(fare)) {
      axios
        .post("/api/v1/payment/record-payment", {
          amount: fare,
          student_id: student_id,
        })
        .then((res) => {
          if (res?.data?.status === 200 || res?.data?.status === 201) {
            Swal.fire("Success", res?.data?.msg, "success");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err?.response);
          if (
            err.response?.data?.status === 400 ||
            err.response?.status === 422
          ) {
            Swal.fire(
              "Failed",
              err?.response?.data?.error_msg || err?.response?.data?.errors,
              "info"
            );
          } else {
            Swal.fire(
              "Processing Failed",
              "Error Recording Bus Payment. Make sure all fields are filled",
              "error"
            );
          }
        })
        .finally(() => {
          setRecording(false);
          setReload(!reload);
        });
    } else {
      setClicked(true);
    }
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };
  const diff = fare-balance;

  const renderPaymentForm = () => (
    <form onSubmit={handleTopUpPayment}>
      <div className="d-flex flex-row justify-content-left align-items-left">
        <input
          type="number"
          className="form-control w-50"
          min={diff > 0 ? diff : fare}
          placeholder="Enter Amount"
          name="amount"
          required
          value={amount}
          onChange={handleAmountChange}
        />

        <Button
          cssClasses="btn btn-primary"
          buttonText="Submit"
          isloading={loading}
        />
      </div>
    </form>
  );

  return (
    <div>
      {clicked  ? (
        renderPaymentForm()
      ) : (
        <Button
          cssClasses="btn btn-secondary"
          buttonText={"Record Payment"}
          callback={handlePayment}
          isloading={isRecording}
        />
      )}
    </div>
  );
}

export default MakePayment;
