import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import Button from "../../../components/customComponents/Button";

function StudentUpload() {
  const [isloading, setLoading] = useState(false);
  const [excel_file, setFile] = useState();

  const handlefileUpload = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("excel_file", excel_file);
      const res = await axios.post("/api/v1/students/import", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res?.status === 200) {
        setLoading(!isloading);
        Swal.fire(
          "Success",
          "Admission List has been Uploaded Successfully",
          "success"
        );
      }
      if (res?.data[0]?.errors) {
        Swal.fire(
          "Error",
          `${res?.data?.map((item) => {
            return `${item?.errors} <br/>This issue occured in row ${item?.row},column ${item?.attribute}<br/>`;
          })}`,
          "info"
        );
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      Swal.fire(
        "Failed",
        err?.response?.data?.error_msg || "Failed to upload the excel file",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={handlefileUpload}>
        <div className="d-flex flex-row ">
          <input
            type="file"
            required
            className="btn-primary form-control text-white "
            id="upload"
            name={excel_file}
            onChange={(e) => setFile(e.target.files[0])}
          />
          <Button
            buttonText={"Upload"}
            isloading={isloading}
            cssClasses={"btn btn-outline-primary me-2"}
          />
          {/* <Button
                    buttonText={"Template"}
                    isloading={isloading}
                    cssClasses={"btn btn-outline-info text-nowrap"}
                  /> */}
          <a
            href="/assets/template.xls"
            target={"_self"}
            className="btn btn-outline-info text-nowrap"
          >
            Template
          </a>
        </div>
      </form>
    </div>
  );
}

export default StudentUpload;
