import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const useGetFetch = (url, reload) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isloaded, setIsLoaded] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    axios
      .get(url)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoaded(true);
          setData(res.data.data);
        }
        if (res.data.status === 201) {
          setIsLoaded(true);
          setData(res.data.msg);
        }
      })
      .catch((err) => {
        setIsLoaded(true);
        setErrors(err?.response);
        console.log(err?.response);
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        } else if (err.response.status === 400) {
          setErrors(err?.response?.data?.error_msg);
        } else if (err.response.status === 403) {
          navigate("/forbidden");
        } else if (err.response.status === 404) {
          navigate("*");
        } else {
          Swal.fire({
            title: "Error",
            text: "Error Loading Records",
            icon: "error",
            confirmButtonColor: "#338540",
          });
        }
      });
  }, [url, reload, navigate]);

  return { data, isloaded, errors };
};

export default useGetFetch;
