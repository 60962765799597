import React from "react";
import axios from "axios";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Authentication/Login";
import routes from "./components/strings/routes";
import endpoints from "./components/strings/endpoints";
import Dashboard from "./pages/Dashboard/Dashboard";
import Home from "./pages/Dashboard/Home";
import Users from "./pages/Dashboard/Users/Users";
import AddUser from "./pages/Dashboard/Users/AddUser";
import Destinations from "./pages/Dashboard/Destinations/Destinations";
import AddDestination from "./pages/Dashboard/Destinations/AddDestination";
import Classrooms from "./pages/Dashboard/Classrooms/Classrooms";
import AddClassroom from "./pages/Dashboard/Classrooms/AddClassroom";
import Students from "./pages/Dashboard/Students/Students";
import AddStudent from "./pages/Dashboard/Students/AddStudent";
import AdvancedPayments from "./pages/Dashboard/Payments/AdvancedPayments";
import AdvancedPaymentForm from "./pages/Dashboard/Payments/AdvancedPaymentForm";
import FarePayments from "./pages/Dashboard/Payments/FarePayments";
import Attendance from "./pages/Dashboard/Attendance/Attendance";
import BulkSms from "./pages/Dashboard/BulkSMS/BulkSms";
import SmsLog from "./pages/Dashboard/BulkSMS/SmsLog";
import GeneralTransactions from "./pages/Dashboard/Transactions/GeneralTransactions";
// import Log from './pages/Dashboard/BulkSMS/Log';

function App() {
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.baseURL = `${endpoints.BASE_URL}`;

  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return (
    <Routes>
      <Route path={routes.INITIAL_ROUTE} element={<Login />} />
      <Route path={routes.DASHBOARD} element={<Dashboard />}>
        <Route path={routes.DASHBOARD} element={<Home />} />
        {localStorage.getItem("role") === "admin" && (
          <>
            <Route path={routes.USERS} element={<Users />} />
            <Route path={routes.ADD_USERS} element={<AddUser />} />
            <Route path={routes.DESTINATIONS} element={<Destinations />} />
            <Route
              path={routes.ADD_DESTINATIONS}
              element={<AddDestination />}
            />
            <Route path={routes.CLASSROOMS} element={<Classrooms />} />
            <Route path={routes.ADD_CLASSROOM} element={<AddClassroom />} />
            <Route path={routes.STUDENTS} element={<Students />} />
            <Route path={routes.ADD_STUDENT} element={<AddStudent />} />
          </>
        )}
        <Route path={routes.ADVANCED_PAYMENTS} element={<AdvancedPayments />} />
        <Route
          path={routes.ADVANCED_PAYMENT_FORM}
          element={<AdvancedPaymentForm />}
        />
        <Route path={routes.FARE_PAYMENT} element={<FarePayments />} />
        <Route path={routes.ATTENDANCE} element={<Attendance />} />

        {localStorage.getItem("role") === "admin" && (
          <>
            <Route path={routes.BULK_SMS} element={<BulkSms />} />
            <Route path={routes.SMS_LOG} element={<SmsLog />} />
          </>
        )}
        <Route
          path={routes.GENERAL_TRANSACTIONS}
          element={<GeneralTransactions />}
        />
      </Route>
    </Routes>
  );
}

export default App;
