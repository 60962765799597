import React, { useState } from "react";
import useGetFetch from "../../../components/customHooks/useGetFetch";
import axios from "axios";
import Button from "../../../components/customComponents/Button";
import Swal from "sweetalert2";

const BulkSms = () => {
  const [message, setMessage] = useState("");
  const [characterCount, setCharacterCount] = useState(0);
  const [toSelection, setToSelection] = useState("all");
  const [classrooms, setClassrooms] = useState(null);
  const { data: departments } = useGetFetch("/api/v1/classroom/departments");
  const { data: destinations } = useGetFetch(
    "/api/v1/destination/activeDestinations"
  );

  const [apiData, setApiData] = useState({
    class_id: null,
    destination_id: null,
  });

  const [loading, setLoading] = useState(false);
  const { data: sms } = useGetFetch("/api/v1/reports/statistics",loading);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setMessage(value);
    setCharacterCount(value.length);
  };

  const handleToSelectionChange = (e) => {
    setToSelection(e.target.value);
  };

  const handleDepartmentChange = (e) => {
    axios
      .get(`/api/v1/classroom/department/${e.target.value}/classes`)
      .then((res) => {
        setClassrooms(res?.data?.data);
      });
  };

  const [receipients, setReceipients] = useState();
  const handleReceipientChange = (e) => {
    const { name, value } = e.target;
    let data = null;
    if (name === "class") {
      setApiData((apiData) => ({
        ...apiData,
        class_id: value,
      }));
      data = {
        type: name,
        class_id: value,
      };
    }

    if (name === "destination") {
      setApiData((apiData) => ({
        ...apiData,
        destination_id: value,
      }));
      data = {
        type: name,
        destination_id: value,
      };
    }
    axios.post(`/api/v1/reports/receipientCount`, data).then((res) => {
      setReceipients(res?.data?.data?.receipients);
    });
  };

  const handleSubmit = async(e) => {
      await setLoading(true);
      e.preventDefault();
    Swal.fire({
      title: "Confirmation",
      text: `Are you sure to send this sms?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      icon: "question",
    })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/api/v1/reports/sms/send", {
              type: toSelection,
              class_id: apiData.class_id,
              destination_id: apiData.destination_id,
              message: message,
            })
            .then((res) => {
              if (res?.status === 200||res?.status === 201) {
                Swal.fire(
                  "Success",
                  "SMS is being Delivered to receipients",
                  "success"
                )
              }
            });
        }
      })
      .finally(setLoading(false));
  };

  return (
    <div className="container">
      <h1 className="h3 mb-3">Send Bulk SMS</h1>

      <div className="row">
        <div className="col-md-10">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>To:</label>
                  <br />
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="to"
                      id="toAll"
                      value="all"
                      checked={toSelection === "all"}
                      onChange={handleToSelectionChange}
                    />
                    <label className="form-check-label" htmlFor="toAll">
                      All
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="to"
                      id="toClass"
                      value="class"
                      checked={toSelection === "class"}
                      onChange={handleToSelectionChange}
                    />
                    <label className="form-check-label" htmlFor="toClass">
                      Class
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="to"
                      id="toDestinations"
                      value="destination"
                      checked={toSelection === "destination"}
                      onChange={handleToSelectionChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="toDestinations"
                    >
                      Destinations
                    </label>
                  </div>
                </div>

                {toSelection !== "all" && (
                  <>
                    {toSelection === "class" && (
                      <>
                        <div className="form-group">
                          <label>Department:</label>
                          <select
                            className="form-control"
                            name="department"
                            required
                            onChange={handleDepartmentChange}
                          >
                            <option value="">Select Department</option>
                            {departments?.map((item, index) => (
                              <option value={item?.id} key={index}>
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Class:</label>
                          <select
                            className="form-control"
                            name="class"
                            required
                            onChange={handleReceipientChange}
                          >
                            <option value="">Select Class</option>
                            {classrooms?.map((item, index) => (
                              <option value={item?.id} key={index}>
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    )}

                    {toSelection === "destination" && (
                      <div className="form-group">
                        <label>Destinations:</label>
                        <select
                          className="form-control"
                          name="destination"
                          required
                          onChange={handleReceipientChange}
                        >
                          <option value="">Select Destination</option>
                          {destinations?.map((item, index) => (
                            <option value={item?.id} key={index}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </>
                )}

                <div className="form-group mt-4">
                  <label>Enter Message:</label>
                  <textarea
                    className="form-control"
                    name="message"
                    required
                    rows="4"
                    maxLength="500"
                    value={message}
                    onChange={handleInputChange}
                  />
                  <small className="form-text text-muted">
                    {characterCount}/500 characters
                  </small>

                  <p className="form-text text-muted mt-2">
                    SMS Balance: <b>{sms?.sms_balance || 0}</b>
                    <br />
                    Total Receipients: <b>{receipients || 0}</b>
                  </p>
                </div>

                <Button
                  cssClasses={"btn btn-primary"}
                  buttonText={"Send SMS"}
                  isloading={loading}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkSms;
