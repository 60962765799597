

const routes={
    INITIAL_ROUTE:'/',
    DASHBOARD:'/dashboard',
    USERS:'/dashboard/users',
    ADD_USERS:'/dashboard/users/add',
    DESTINATIONS:'/dashboard/destinations',
    ADD_DESTINATIONS:'/dashboard/destinations/add',
    CLASSROOMS:'/dashboard/classrooms',
    ADD_CLASSROOM:'/dashboard/classrooms/add',
    STUDENTS:'/dashboard/students',
    ADD_STUDENT:'/dashboard/students/add',
    ADVANCED_PAYMENTS:'/dashboard/advanced-payments',
    ADVANCED_PAYMENT_FORM:'/dashboard/advanced-payment/:student_id',
    FARE_PAYMENT:'/dashboard/fare-payment/',
    ATTENDANCE:'/dashboard/attendance/',
    BULK_SMS:'/dashboard/bulk-sms/',
    SMS_LOG:'/dashboard/logs',
    GENERAL_TRANSACTIONS:'/dashboard/transactions'
}

export default routes;