import React from "react";
import Card from "../../components/customComponents/Card";
import useGetFetch from "../../components/customHooks/useGetFetch";

function Home() {
  const {data}=useGetFetch('/api/v1/reports/statistics');

  return (
    <div>
      <h1 className="h3 mb-3">Analytics Dashboard</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex">
          <div className="w-100">
            <div className="row">
              <div className="col-sm-3">
                <Card title={"Total Students"} value={data?.total_students} icon={"fa fa-users"}/>
              </div>
              <div className="col-sm-3">
                <Card title={"SMS Balance"} value={data?.sms_balance} icon={"fa fa-envelope"}/>
              </div>
              <div className="col-sm-3">
                <Card title={"Total Payments"} />
              </div>
              <div className="col-sm-3">
                <Card title={"Total Routes"} value={data?.total_routes} icon={"fa fa-map"}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-12d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <h5 className="card-title mb-0">Latest Projects</h5>
            </div>
            <table className="table table-hover my-0">
              <thead>
                <tr>
                  <th>Name</th>
                  <th className="d-none d-xl-table-cell">Start Date</th>
                  <th className="d-none d-xl-table-cell">End Date</th>
                  <th>Status</th>
                  <th className="d-none d-md-table-cell">Assignee</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Project Apollo</td>
                  <td className="d-none d-xl-table-cell">01/01/2021</td>
                  <td className="d-none d-xl-table-cell">31/06/2021</td>
                  <td>
                    <span className="badge bg-success">Done</span>
                  </td>
                  <td className="d-none d-md-table-cell">Vanessa Tucker</td>
                </tr>
                <tr>
                  <td>Project Fireball</td>
                  <td className="d-none d-xl-table-cell">01/01/2021</td>
                  <td className="d-none d-xl-table-cell">31/06/2021</td>
                  <td>
                    <span className="badge bg-danger">Cancelled</span>
                  </td>
                  <td className="d-none d-md-table-cell">William Harris</td>
                </tr>
                <tr>
                  <td>Project Hades</td>
                  <td className="d-none d-xl-table-cell">01/01/2021</td>
                  <td className="d-none d-xl-table-cell">31/06/2021</td>
                  <td>
                    <span className="badge bg-success">Done</span>
                  </td>
                  <td className="d-none d-md-table-cell">Sharon Lessman</td>
                </tr>
                <tr>
                  <td>Project Nitro</td>
                  <td className="d-none d-xl-table-cell">01/01/2021</td>
                  <td className="d-none d-xl-table-cell">31/06/2021</td>
                  <td>
                    <span className="badge bg-warning">In progress</span>
                  </td>
                  <td className="d-none d-md-table-cell">Vanessa Tucker</td>
                </tr>
                <tr>
                  <td>Project Phoenix</td>
                  <td className="d-none d-xl-table-cell">01/01/2021</td>
                  <td className="d-none d-xl-table-cell">31/06/2021</td>
                  <td>
                    <span className="badge bg-success">Done</span>
                  </td>
                  <td className="d-none d-md-table-cell">William Harris</td>
                </tr>
                <tr>
                  <td>Project X</td>
                  <td className="d-none d-xl-table-cell">01/01/2021</td>
                  <td className="d-none d-xl-table-cell">31/06/2021</td>
                  <td>
                    <span className="badge bg-success">Done</span>
                  </td>
                  <td className="d-none d-md-table-cell">Sharon Lessman</td>
                </tr>
                <tr>
                  <td>Project Romeo</td>
                  <td className="d-none d-xl-table-cell">01/01/2021</td>
                  <td className="d-none d-xl-table-cell">31/06/2021</td>
                  <td>
                    <span className="badge bg-success">Done</span>
                  </td>
                  <td className="d-none d-md-table-cell">Christina Mason</td>
                </tr>
                <tr>
                  <td>Project Wombat</td>
                  <td className="d-none d-xl-table-cell">01/01/2021</td>
                  <td className="d-none d-xl-table-cell">31/06/2021</td>
                  <td>
                    <span className="badge bg-warning">In progress</span>
                  </td>
                  <td className="d-none d-md-table-cell">William Harris</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Home;
