import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useGetFetch from "../../../components/customHooks/useGetFetch";
import axios from "axios";
import Swal from "sweetalert2";
import routes from "../../../components/strings/routes";
import Button from "../../../components/customComponents/Button";

function AdvancedPaymentForm() {
  const { student_id } = useParams();

  const { data: student } = useGetFetch(`/api/v1/students/${student_id}`);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    paymentType: "",
    destinationCharge: "",
    totalCharge: "",
    amountPaid: "",
    desc: "",
    errors: {},
  });

  function handleUserInput(event) {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "paymentType") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        totalCharge: Number(student?.destinations?.fare) * Number(value),
        desc: `Advanced payment deposit amount of ${
          Number(student?.destinations?.fare) * Number(value)
        }`,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  }

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/api/v1/payment/advanced-payment", {
        amount: formData?.amountPaid,
        student_id: student?.id,
        desc:formData?.desc
      })
      .then((res) => {
        if (res?.data?.status === 200 || res?.data?.status === 201) {
          Swal.fire(
            "Success",
            "Advanced Payment amount has been recorded successfully",
            "success"
          ).then(() => navigate(routes.ADVANCED_PAYMENTS));
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err?.response);
        if (err.response.data.status === 400 || err.response.status === 422) {
          setFormData((userInput) => ({
            ...userInput,
            errors:
              err?.response?.data?.error_msg || err?.response?.data?.errors,
          }));
        } else {
          Swal.fire('Processing Failed','Error Recording Advanced Payment. Make sure all fields are filled','error');
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <h3 className="text-center">Record Advanced Payment</h3>
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Student's Name:</label>
              <input
                type="text"
                name="name"
                id="name"
                className="form-control text-capitalize"
                value={`${student?.surname} ${student?.firstname} ${
                  student?.othername || ""
                }`}
                onChange={handleUserInput}
                readOnly
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="name">Class:</label>
              <input
                type="text"
                name="name"
                id="name"
                className="form-control text-capitalize"
                value={student?.classrooms?.name}
                onChange={handleUserInput}
                readOnly
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="name">Destination:</label>
              <input
                type="text"
                name="name"
                id="name"
                className="form-control text-capitalize"
                value={student?.destinations?.name}
                onChange={handleUserInput}
                readOnly
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="destinationCharge">Destination Charge:</label>
              <input
                type="number"
                name="destinationCharge"
                id="destinationCharge"
                className="form-control"
                value={student?.destinations?.fare}
                onChange={handleUserInput}
                readOnly
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="paymentType">Select Payment Type:</label>
              <select
                name="paymentType"
                id="paymentType"
                className="form-control"
                value={formData.paymentType}
                onChange={handleUserInput}
                required
              >
                <option value="">Select Payment Type</option>
                <option value={7}>Weekly (7 Days)</option>
                <option value={31}>Monthly (31 Days)</option>
              </select>
            </div>

            <div className="form-group mt-3">
              <label htmlFor="totalCharge">Total Charge:</label>
              <input
                type="number"
                name="totalCharge"
                id="totalCharge"
                className="form-control"
                readOnly
                value={Number(formData.totalCharge).toFixed(2)}
                onChange={handleUserInput}
              />
            </div>

            <div className="form-group mt-3">
              <label htmlFor="amountPaid">Amount Paid:</label>
              <input
                type="number"
                name="amountPaid"
                id="amountPaid"
                className="form-control"
                value={formData.amountPaid}
                onChange={handleUserInput}
                required
              />
            </div>

            <Button
              cssClasses={"btn btn-primary mt-3"}
              buttonText={"Submit"}
              isloading={loading}
            />
            <button
              type="submit"
              className="btn btn-secondary mt-3 ms-2"
              onClick={() => navigate(routes?.ADVANCED_PAYMENTS)}
            >
              Back
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default AdvancedPaymentForm;
