/* eslint-disable array-callback-return */
import React, { useState } from "react";
import LoadingPage from "../../../components/customComponents/LoadingPage";
import Button from "../../../components/customComponents/Button";
import Card from "../../../components/customComponents/Card";
import axios from "axios";
import Swal from "sweetalert2";
import { ConvertDate } from "../../../components/helpers/ConvertDate";
import Pagination from "../../../components/helpers/Pagination";

function GeneralTransactions() {
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    from: "",
    to: "",
  });

  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(0);
  const handlePageClicked = (e) => {
    setPage(e.selected + 1);
    handleLoadTransactions(null);
  };

  const [response, setResponse] = useState([]);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const [filter,setFilter]=useState("");

  const handleLoadTransactions = (e) => {
    setLoading(true);
    e.preventDefault();
   
    axios
      .get(`/api/v1/reports/transactions/${userInput?.from}/${userInput?.to}?page=${page}`)
      .then((res) => {
        setResponse(res?.data?.data);
      })
      .catch((err) => {
        Swal.fire("Error", "Error Retriving Transactions", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <h1 className="h3 mb-3">Transactions Log</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex">
          <div className="w-100">
            <div className="row">
              <div className="col-sm-3">
                <Card
                  title={"Advanced Payments"}
                  value={Number(response?.advanced_payments || 0).toFixed(2)}
                />
              </div>
              <div className="col-sm-3">
                <Card
                  title={"Total Fare Payments"}
                  value={Number(response?.fare_payments || 0).toFixed(2)}
                />
              </div>
              <div className="col-sm-3">
                <Card
                  title={"Total Cash Recieved"}
                  value={Number(
                    Number(response?.advanced_payments || 0) +
                      Number(response?.fare_payments || 0)
                  ).toFixed(2)}
                />
              </div>
              <div className="col-sm-3">
                <Card
                  title={"Current Student Balance(s)"}
                  value={response?.student_balance || 0}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex">
          <div className="w-100">
            <div className="row">
              <div className="col-12d-flex">
                <div className="card flex-fill">
                  <div className="card-header">
                    <div className="d-flex flex-row justify-content-between">
                      <h5 className="card-title mb-0">List of Students</h5>
                    </div>
                    <form onSubmit={handleLoadTransactions}>
                      <div className="row">
                        <div className="col-md-2 form-group mt-3">
                          <label htmlFor="date">Select Date</label>
                          <input
                            type="date"
                            className="form-control"
                            name="from"
                            required
                            defaultValue={""}
                            onChange={handleUserInput}
                          />
                        </div>
                        <div className="col-md-2 form-group mt-3">
                          <label htmlFor="date">Select Date</label>
                          <input
                            type="date"
                            className="form-control"
                            name="to"
                            required
                            defaultValue={""}
                            onChange={handleUserInput}
                          />
                        </div>
                        <div className="col-md-3 pt-2">
                          <div className="d-flex">
                            <Button
                              buttonText={"Load"}
                              cssClasses={"btn mt-4 btn-outline-success"}
                              isloading={loading}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mt-2">
                          <input type="text" placeholder="Search by class or student" className="form-control" value={filter} onChange={(e)=>setFilter(e.target.value.toLowerCase())}/>
                        </div>
                      </div>
                    </form>
                  </div>
                  <table className="table table-hover my-0">
                    <thead>
                      <tr>
                        <th>Student</th>
                        <th className="d-none d-md-table-cell">Class</th>

                        <th className="d-none d-md-table-cell">Debit</th>
                        <th className="d-none d-md-table-cell">Credit</th>
                        <th className="d-none d-md-table-cell">Type</th>
                        <th className="d-none d-md-table-cell">Description</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    {loading ? (
                      <LoadingPage />
                    ) : (
                      <>
                        <tbody>
                          {response?.transactions?.data?.filter((item)=>{
                            if(filter==="" || filter===null){
                              return item
                            }else if(item?.student?.firstname?.toLowerCase().includes(filter.toLowerCase())||
                            item?.student?.surname?.toLowerCase().includes(filter.toLowerCase())||
                            item?.student?.othername?.toLowerCase().includes(filter.toLowerCase())||
                            item?.student?.classrooms?.name?.toLowerCase().includes(filter.toLowerCase())
                            ){
                              return item;
                            }
                            
                          })?.map((data, index) => (
                            <tr key={index}>
                              <td className="text-capitalize">{`${
                                data?.student?.firstname
                              } ${data?.student?.surname} ${
                                data?.student?.othername || ""
                              }`}</td>

                              <td className="d-none d-md-table-cell text-capitalize">
                                {data?.student?.classrooms?.name}
                              </td>

                              <td className="d-none d-md-table-cell text-capitalize">
                                {data?.debit}
                              </td>

                              <td>{data?.credit}</td>
                              <td>{data?.payment_type}</td>
                              <td>{data?.description}</td>
                              <td>{ConvertDate(data?.created_at)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </>
                    )}
                  </table>
                  <Pagination
                    pageCount={response?.transactions?.last_page}
                    handlePageClick={handlePageClicked}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralTransactions;
