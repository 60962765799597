import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Button from "../../../components/customComponents/Button";
import LoadingPage from "../../../components/customComponents/LoadingPage";
import useGetFetch from "../../../components/customHooks/useGetFetch";
import { useNavigate } from "react-router-dom";

function AdvancedPayments() {
  const navigate = useNavigate();
  const { data: departments } = useGetFetch("/api/v1/classroom/departments");

  const [userInput, setUserInput] = useState({
    department_id: "",
    classroom_id: "",
  });

  const [loading, setLoading] = useState(false);

  const [classrooms, setClassrooms] = useState();
  const [students, setStudents] = useState();

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === "department_id" && e.target.value !== "") {
      axios
        .get(`/api/v1/classroom/department/${e.target.value}/classes`)
        .then((res) => {
          setClassrooms(res?.data?.data);
        });
    }
  };

  const [filter, setFilter] = useState("");

  useEffect(() => {
    axios
      .get(`/api/v1/payment/class/${userInput?.classroom_id}/list/${filter}`)
      .then((res) => {
        if (res?.status === 200) {
          setStudents(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err?.response);
        //   Swal.fire("Error", "Error Loading Class List", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleLoadStudents = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .get(`/api/v1/payment/class/${userInput?.classroom_id}/list`)
      .then((res) => {
        if (res?.status === 200) {
          setStudents(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err?.response);
        Swal.fire("Error", "Error Loading Class List", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };


  return (
    <div>
      <h1 className="h3 mb-3">Advanced Payment</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex">
          <div className="w-100">
            <div className="row">
              <div className="col-12d-flex">
                <div className="card flex-fill">
                  <div className="card-header">
                    <div className="d-flex flex-row justify-content-between">
                      <h5 className="card-title mb-0">List of Students</h5>
                    </div>
                    <form onSubmit={handleLoadStudents}>
                      <div className="row">
                        <div className="col-md-3 form-group mt-3">
                          <label htmlFor="department_id">Department:</label>
                          <select
                            className="form-control"
                            id="department_id"
                            name="department_id"
                            onChange={handleUserInput}
                            value={userInput?.department}
                          >
                            <option value="" disabled selected>
                              --Select department--
                            </option>
                            {departments?.map((item, index) => (
                              <option value={item?.id} key={index}>
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-3 form-group mt-3">
                          <label htmlFor="department_id">Class:</label>
                          <select
                            className="form-control"
                            name="classroom_id"
                            required
                            onChange={handleUserInput}
                            value={userInput?.classroom}
                          >
                            <option value="" disabled selected>
                              --Select Class--
                            </option>
                            {classrooms?.map((item, index) => (
                              <option value={item?.id} key={index}>
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-3 pt-2">
                          <div className="d-flex">
                            <Button
                              buttonText={"Load"}
                              cssClasses={"btn mt-4 btn-outline-success"}
                              isloading={loading}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 pt-2 mt-4">
                        <div className="d-flex">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search for Student Here..."
                            value={filter}
                            onChange={(e)=>setFilter(e.target.value)}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <table className="table table-hover my-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th className="d-none d-md-table-cell">Class </th>
                        <th className="d-none d-md-table-cell">Destination</th>
                        <th className="d-none d-md-table-cell text-center">Balance</th>
                        <th className="d-none d-md-table-cell">Contact</th>
                        <th>Status</th>
                        <th className="d-none d-md-table-cell">Actions</th>
                      </tr>
                    </thead>
                    {loading ? (
                      <LoadingPage />
                    ) : (
                      <tbody>
                        {students?.map((student, index) => (
                          <tr key={index}>
                            <td className="text-capitalize">{`${
                              student?.firstname
                            } ${student?.surname} ${
                              student?.othername || ""
                            }`}</td>
                            <td className="d-none d-md-table-cell text-capitalize">
                              {student?.classrooms?.name}
                            </td>
                            <td className="d-none d-md-table-cell text-capitalize">
                              {student?.destinations?.name}
                            </td>
                            <td className="d-none d-md-table-cell text-capitalize text-center">
                              GHC {student?.balance?.amount|| '0.00'}
                            </td>
                            <td className="d-none d-md-table-cell text-capitalize">
                              {student?.phone}
                            </td>

                            <td>
                              <span
                                className={`badge text-capitalize ${
                                  student?.status === "active"
                                    ? "bg-success"
                                    : "bg-danger"
                                } `}
                              >
                                {student?.status}
                              </span>
                            </td>
                            <td className="d-none d-md-table-cell text-nowrap">
                              <button className="btn btn-secondary" onClick={()=>navigate(`/dashboard/advanced-payment/${student?.id}`)}>
                                <i
                                  className="fas fa-pencil"
                                  title="Record Payment"
                                ></i>&nbsp; Record Payment
                              </button>
                             
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvancedPayments;
