import React from "react";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../../components/customComponents/LoadingPage";
import useGetFetch from "../../../components/customHooks/useGetFetch";
import routes from "../../../components/strings/routes";

function Classrooms() {
  const navigate = useNavigate();

  const { data, isloaded } = useGetFetch("/api/v1/classroom/all");

  return (
    <div>
      <h1 className="h3 mb-3">Manage Classes</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex">
          <div className="w-100">
            <div className="row">
              <div className="col-12d-flex">
                <div className="card flex-fill">
                  <div className="card-header">
                    <div className="d-flex flex-row justify-content-between">
                      <h5 className="card-title mb-0">List of Classrooms</h5>
                      <button
                        className="btn btn-primary"
                        onClick={() => navigate(routes.ADD_CLASSROOM)}
                      >
                        Setup Classroom
                      </button>
                    </div>
                  </div>
                  {isloaded ? (
                    <table className="table table-hover my-0">
                      <thead>
                        <tr>
                          <th className="d-none d-md-table-cell">Department </th>
                          <th>Class Title</th>
                          <th className="d-none d-md-table-cell">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((classroom, index) => (
                          <tr key={index}>
                            <td className="d-none d-md-table-cell">
                              {classroom?.department?.name}
                            </td>
                            <td className="text-capitalize">{classroom?.name}</td>

                            <td className="d-none d-md-table-cell text-nowrap">
                              <button className="btn btn-secondary">
                                <i
                                  className="fas fa-pencil"
                                  title="Edit User"
                                ></i>
                              </button>
                              <button className="btn btn-danger ms-2">
                                <i className="fas fa-trash" title="Block"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="d-flex justify-content-center">
                    <LoadingPage />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Classrooms;
