import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../components/customComponents/Button";
import useGetFetch from "../../../components/customHooks/useGetFetch";
import routes from "../../../components/strings/routes";

function AddClassroom() {
  const navigate = useNavigate();
  const { data:departments } = useGetFetch("/api/v1/classroom/departments");

  const [userInput,setUserInput]=useState({
    name:'',
    department_id:'',
    errors: {},
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const [isloading,setLoading]=useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/api/v1/classroom/create", userInput)
      .then((res) => {
        if (res?.data?.status === 200 || res?.data?.status === 201) {
          Swal.fire(
            "Success",
            "User account has been created successfully",
            "success"
          ).then(()=>navigate(routes.CLASSROOMS))
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err?.response);
        if (err.response.data.status === 400 || err.response.status === 422) {
          setUserInput((userInput) => ({
            ...userInput,
            errors: err?.response?.data?.error_msg||err?.response?.data?.errors,
          }));
        } else {
          Swal.fire("Error", "Classroom Creation Failed", "error");
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <h1 className="h3 mb-3 text-center">Add New Classroom</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex justify-content-center">
          <div className="w-75">
            <div className="row">
              <div className="card p-3">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      onChange={handleUserInput}
                      value={userInput?.name}
                      placeholder="Enter classroom name"
                    />
                     <small className="text-danger">
                      {userInput?.errors?.name}
                    </small>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="department_id">Department:</label>
                    <select
                      className="form-control"
                      id="department_id"
                      name="department_id"
                      onChange={handleUserInput}
                      value={userInput?.department_id}
                    >
                      <option value="" disabled selected>
                        Select department
                      </option>
                      {departments?.map((item, index) => (
                        <option value={item?.id} key={index}>{item?.name}</option>
                      ))}
                    </select>
                    <small className="text-danger">
                      {userInput?.errors?.department_id}
                    </small>
                  </div>
                  <Button
                    cssClasses={"btn btn-primary mt-2"}
                    buttonText={"Save"}
                    isloading={isloading}
                  />
                  <button
                    type="submit"
                    className="btn btn-secondary mt-3 ms-2"
                    onClick={() => navigate(routes.CLASSROOMS)}
                  >
                    Go Back
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddClassroom;
