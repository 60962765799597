import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import routes from "../../components/strings/routes";

function Dashboard() {
  const [sideBarCollapsed, setSideBarCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = (e) => {
    e.preventDefault();
    navigate("/");
  };

  return (
    <div className="wrapper">
      <nav
        id="sidebar"
        className={`sidebar js-sidebar ${sideBarCollapsed && "collapsed"}`}
      >
        <div className="sidebar-content js-simplebar">
          <a className="sidebar-brand" href="index.html">
            <span className="align-middle">FarePal</span>
          </a>

          <ul className="sidebar-nav">
            {localStorage.getItem("role") === "admin" && (
              <>
                <li className="sidebar-header">Admin</li>
                <SidebarItem
                  title={"Dashboard"}
                  icon={"fa fa-gauge"}
                  path={routes.DASHBOARD}
                  pathname={location.pathname}
                />
                <SidebarItem
                  title={"Users"}
                  icon={"fas fa-user"}
                  path={routes.USERS}
                  pathname={location.pathname}
                />
                <SidebarItem
                  title={"Routes"}
                  icon={"fas fa-map"}
                  path={routes.DESTINATIONS}
                  pathname={location.pathname}
                />
                <SidebarItem
                  title={"Class Rooms"}
                  icon={"fas fa-landmark"}
                  path={routes.CLASSROOMS}
                  pathname={location.pathname}
                />
                <SidebarItem
                  title={"Students"}
                  icon={"fa fa-users"}
                  path={routes.STUDENTS}
                  pathname={location.pathname}
                />
              </>
            )}
            <>
              <li className="sidebar-header">Payment & Attendance</li>
              <SidebarItem
                title={"Fare Payments"}
                icon={"fa fa-money-check-alt"}
                path={routes.FARE_PAYMENT}
                pathname={location.pathname}
              />
              <SidebarItem
                title={"Advanced Payments"}
                icon={"fa fa-plus-circle"}
                path={routes.ADVANCED_PAYMENTS}
                pathname={location.pathname}
              />
              <SidebarItem
                title={"Manage Attendance"}
                icon={"fa fa-check-circle"}
                path={routes.ATTENDANCE}
                pathname={location.pathname}
              />

              <SidebarItem
                title={"General Transactions"}
                icon={"fa fa-archive"}
                path={routes.GENERAL_TRANSACTIONS}
                pathname={location.pathname}
              />
            </>

            {localStorage.getItem("role") === "admin" && (
              <>
                <li className="sidebar-header">Others</li>
                <SidebarItem
                  title={"Bulk SMS"}
                  icon={"fa fa-envelope"}
                  path={routes.BULK_SMS}
                  pathname={location.pathname}
                />
                <SidebarItem
                  title={"SMS Log"}
                  icon={"fa fa-book"}
                  path={routes.SMS_LOG}
                  pathname={location.pathname}
                />
              </>
            )}
            <li className="sidebar-item">
              <button
                onClick={() => {
                  localStorage.clear();
                  navigate("/");
                }}
                className="btn btn-secondary rounded-right sidebar-link"
              >
                <i className="align-middle" data-feather="align-left"></i>{" "}
                <span className="align-middle">Signout</span>
              </button>
            </li>
          </ul>
        </div>
      </nav>

      <div className="main">
        <nav className="navbar navbar-expand navbar-light navbar-bg">
          <a
            href={() => {}}
            className="sidebar-toggle js-sidebar-toggle"
            onClick={() => setSideBarCollapsed(!sideBarCollapsed)}
          >
            <i className="hamburger align-self-center"></i>
          </a>

          <div className="navbar-collapse collapse">
            <ul className="navbar-nav navbar-align">
              <li className="nav-item dropdown">
                <a
                  className="nav-icon dropdown-toggle d-inline-block d-sm-none"
                  href="/"
                  data-bs-toggle="dropdown"
                >
                  <i className="align-middle" data-feather="settings"></i>
                </a>

                <a
                  className="nav-link dropdown-toggle d-none d-sm-inline-block"
                  href="/"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src="/img/avatars/avatar.png"
                    className="avatar img-fluid rounded me-1"
                    alt="Charles Hall"
                  />{" "}
                  <span className="text-dark">Charles Hall</span>
                </a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href="pages-profile.html">
                    <i className="align-middle me-1" data-feather="user"></i>{" "}
                    Profile
                  </a>
                  <a className="dropdown-item" href="/">
                    <i
                      className="align-middle me-1"
                      data-feather="pie-chart"
                    ></i>{" "}
                    Analytics
                  </a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="index.html">
                    <i
                      className="align-middle me-1"
                      data-feather="settings"
                    ></i>{" "}
                    Settings & Privacy
                  </a>
                  <a className="dropdown-item" href="/">
                    <i
                      className="align-middle me-1"
                      data-feather="help-circle"
                    ></i>{" "}
                    Help Center
                  </a>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item" onClick={handleLogout}>
                    Log out
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </nav>

        <main className="content">
          <div className="container-fluid p-0">
            <Outlet />
          </div>
        </main>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row text-muted">
              <div className="col-6 text-start">
                <p className="mb-0">
                  <a
                    className="text-muted"
                    href="https://adminkit.io/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>FarePal</strong>
                  </a>{" "}
                  &copy;
                </p>
              </div>
              <div className="col-6 text-end">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <a
                      className="text-muted"
                      href="https://adminkit.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Support
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      className="text-muted"
                      href="https://adminkit.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Help Center
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      className="text-muted"
                      href="https://adminkit.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      className="text-muted"
                      href="https://adminkit.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

const SidebarItem = ({ title, icon, path, pathname }) => {
  const navigate = useNavigate();

  return (
    <li
      className={`sidebar-item ${pathname === path && "active"}`}
      onClick={() => navigate(path)}
    >
      <a className="sidebar-link" href={() => navigate(path)}>
        <i className={`text-white align-middle ${icon}`}></i>
        <span className="align-middle">{title}</span>
      </a>
    </li>
  );
};

export default Dashboard;
