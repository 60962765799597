import React, { useState } from "react";
import LoadingPage from "../../../components/customComponents/LoadingPage";
import axios from "axios";
import useGetFetch from "../../../components/customHooks/useGetFetch";
import Swal from "sweetalert2";
import Button from "../../../components/customComponents/Button";
import Card from "../../../components/customComponents/Card";

function Attendance() {
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [classrooms, setClassrooms] = useState();

  const { data: departments } = useGetFetch("/api/v1/classroom/departments");

  const [userInput, setUserInput] = useState({
    department_id: "",
    classroom_id: "",
    status: "",
    date: "",
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === "department_id" && e.target.value !== "") {
      axios
        .get(`/api/v1/classroom/department/${e.target.value}/classes`)
        .then((res) => {
          setClassrooms(res?.data?.data);
        });
    }
  };

  const handleLoadStudents = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`/api/v1/reports/attendance`, userInput)
      .then((res) => {
        if (res?.status === 200) {
          setStudents(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err?.response);
        Swal.fire("Error", "Error Loading Class List", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      <h1 className="h3 mb-3">Manage Attendance</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex">
          <div className="w-100">
            <div className="row">
              <div className="col-sm-4">
                <Card title={"Registered Students"} value={students?.total_students||0}/>
              </div>
              <div className="col-sm-4">
                <Card title={"Total Present"} value={students?.total_present||0}/>
              </div>
              <div className="col-sm-4">
                <Card title={"Total Absent"} value={students?.total_absent||0}/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex">
          <div className="w-100">
            <div className="row">
              <div className="col-12d-flex">
                <div className="card flex-fill">
                  <div className="card-header">
                    <div className="d-flex flex-row justify-content-between">
                      <h5 className="card-title mb-0">List of Students</h5>
                    </div>
                    <form onSubmit={handleLoadStudents}>
                      <div className="row">
                        <div className="col-md-2 form-group mt-3">
                          <label htmlFor="department_id">Department:</label>
                          <select
                            className="form-control"
                            id="department_id"
                            name="department_id"
                            defaultValue={""}
                            onChange={handleUserInput}
                            value={userInput?.department}
                          >
                            <option value="" disabled selected>
                              --Select department--
                            </option>
                            {departments?.map((item, index) => (
                              <option value={item?.id} key={index}>
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-2 form-group mt-3">
                          <label htmlFor="department_id">Class:</label>
                          <select
                            className="form-control"
                            name="classroom_id"
                            required
                            defaultValue={""}
                            onChange={handleUserInput}
                            value={userInput?.classroom}
                          >
                            <option value="" disabled selected>
                              --Select Class--
                            </option>
                            {classrooms?.map((item, index) => (
                              <option value={item?.id} key={index}>
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-3 form-group mt-3">
                          <label
                            htmlFor="department_id"
                            className="text-nowrap"
                          >
                            Attandance Status:
                          </label>
                          <select
                            className="form-control"
                            name="status"
                            required
                            defaultValue={""}
                            onChange={handleUserInput}
                            value={userInput?.status}
                          >
                            <option value="">--Select Status--</option>
                            <option value="present">Present</option>
                            <option value="absent">Absent</option>
                          </select>
                        </div>
                        <div className="col-md-2 form-group mt-3">
                          <label htmlFor="date">Select Date</label>
                          <input
                            type="date"
                            className="form-control"
                            name="date"
                            required
                            defaultValue={""}
                            onChange={handleUserInput}
                            value={userInput?.date}
                          />
                        </div>
                        <div className="col-md-3 pt-2">
                          <div className="d-flex">
                            <Button
                              buttonText={"Load"}
                              cssClasses={"btn mt-4 btn-outline-success"}
                              isloading={loading}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <table className="table table-hover my-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th className="d-none d-md-table-cell">Destination</th>

                        <th className="d-none d-md-table-cell">Contact</th>
                        <th>Status</th>
                       
                      </tr>
                    </thead>
                    {loading ? (
                      <LoadingPage />
                    ) : (
                      <>
                        <tbody>
                          {students?.students?.map((student, index) => (
                            <tr key={index}>
                              <td className="text-capitalize">{`${
                                student?.firstname
                              } ${student?.surname} ${
                                student?.othername || ""
                              }`}</td>

                              <td className="d-none d-md-table-cell text-capitalize">
                                {student?.destinations?.name}
                              </td>

                              <td className="d-none d-md-table-cell text-capitalize">
                                {student?.phone}
                              </td>

                              <td>
                                <span
                                  className={`badge text-capitalize ${
                                    student?.status === "active"
                                      ? "bg-success"
                                      : "bg-danger"
                                  } `}
                                >
                                  {student?.status}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Attendance;
