import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../components/customComponents/Button";
import routes from "../../../components/strings/routes";
import useGetFetch from "../../../components/customHooks/useGetFetch";

function AddStudent() {
  const navigate = useNavigate();
  const { data: departments } = useGetFetch("/api/v1/classroom/departments");
  const { data: destinations } = useGetFetch("/api/v1/destination/activeDestinations");

  const [classrooms, setClassrooms] = useState();

  const [userInput, setUserInput] = useState({
    surname: "",
    firstname: "",
    othername: "",
    guardian: "",
    phone: "",
    status: "",
    classroom_id: "",
    destination_id: "",
    errors: {},
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === "department_id" && e.target.value !== "") {
      axios
        .get(`/api/v1/classroom/department/${e.target.value}/classes`)
        .then((res) => {
          setClassrooms(res?.data?.data);
        });
    }
  };

  const [isloading, setLoading] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/api/v1/students/create", userInput)
      .then((res) => {
        if (res?.data?.status === 200 || res?.data?.status === 201) {
          Swal.fire(
            "Success",
            "Student Registration has been completed successfully",
            "success"
          ).then(() => navigate(routes.STUDENTS));
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err?.response);
        if (err.response.data.status === 400 || err.response.status === 422) {
          setUserInput((userInput) => ({
            ...userInput,
            errors:
              err?.response?.data?.error_msg || err?.response?.data?.errors,
          }));
        } else {
          Swal.fire("Error", "Student Registration Failed", "error");
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <h1 className="h3 mb-3 text-center">Add New Student</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex justify-content-center">
          <div className="w-75">
            <div className="row">
              <div className="card p-3">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="firstname">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstname"
                      name="firstname"
                      onChange={handleUserInput}
                      value={userInput?.firstname}
                      required
                      placeholder="Enter first name"
                    />
                    <small className="text-danger">
                      {userInput?.errors?.firstname}
                    </small>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="surname">Surname</label>
                    <input
                      type="text"
                      className="form-control"
                      id="surname"
                      name="surname"
                      required
                      value={userInput?.surname}
                      placeholder="Enter surname"
                      onChange={handleUserInput}
                    />
                    <small className="text-danger">
                      {userInput?.errors?.surname}
                    </small>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="othername">Other Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="othername"
                      name="othername"
                      value={userInput?.othername}
                      onChange={handleUserInput}
                      placeholder="Enter Other name"
                    />
                    <small className="text-danger">
                      {userInput?.errors?.othername}
                    </small>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="guardian">Guardian</label>
                    <input
                      type="text"
                      className="form-control"
                      id="guardian"
                      name="guardian"
                      value={userInput?.guardian}
                      onChange={handleUserInput}
                      required
                      placeholder="Enter guardian name"
                    />
                    <small className="text-danger">
                      {userInput?.errors?.guardian}
                    </small>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="phone">Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      name="phone"
                      onChange={handleUserInput}
                      value={userInput?.phone}
                      placeholder="Enter phone number"
                    />
                    <small className="text-danger">
                      {userInput?.errors?.phone}
                    </small>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="department_id">Category</label>
                    <select
                      className="form-control"
                      id="department_id"
                      name="department_id"
                      onChange={handleUserInput}
                      value={userInput?.department}
                    >
                      <option value="" disabled selected>
                        --Select department--
                      </option>
                      {departments?.map((item, index) => (
                        <option value={item?.id} key={index}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <small className="text-danger">
                      {userInput?.errors?.department_id}
                    </small>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="classroom_id">Classroom</label>
                    <select
                      className="form-control"
                      name="classroom_id"
                      required
                      onChange={handleUserInput}
                      value={userInput?.classroom}
                    >
                      <option value="" disabled selected>
                        --Select Class--
                      </option>
                      {classrooms?.map((item, index) => (
                        <option value={item?.id} key={index}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <small className="text-danger">
                      {userInput?.errors?.classroom_id}
                    </small>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="destination_id">Destination</label>
                    <select
                      className="form-control"
                      name="destination_id"
                      required
                      onChange={handleUserInput}
                      value={userInput?.destination_id}
                    >
                      <option value="" disabled selected>
                        --Select Location--
                      </option>
                      {destinations?.map((item, index) => (
                        <option value={item?.id} key={index}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <small className="text-danger">
                      {userInput?.errors?.destination_id}
                    </small>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="status">Status</label>
                    <select
                      className="form-control"
                      id="status"
                      name="status"
                      onChange={handleUserInput}
                      value={userInput?.status}
                    >
                      <option value="active">Active</option>
                      <option value="blocked">Blocked</option>
                    </select>
                    <small className="text-danger">
                      {userInput?.errors?.status}
                    </small>
                  </div>
                  <div className="mt-3">
                    <Button
                      cssClasses={"btn btn-primary"}
                      buttonText={"Submit"}
                      isloading={isloading}
                    />
                    <button
                      type="submit"
                      className="btn btn-secondary ms-2"
                      onClick={() => navigate(routes.STUDENTS)}
                    >
                      Back
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddStudent;
