

export const ConvertDate=(date)=>{
    const formatteddate=new Date(date);
    const newdate= formatteddate.toLocaleDateString();
    const newtime=formatteddate.toLocaleTimeString();
    return newdate+' '+newtime
  }


