import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../components/strings/routes";
import axios from "axios";
import Swal from "sweetalert2";
import Button from "../../../components/customComponents/Button";

function AddDestination() {
    const navigate=useNavigate();
    const [isloading, setLoading] = useState(false);
    const [userInput, setUserInput] = useState({
      name: "",
      fare: "",
      status: "",
      errors: {},
    });
  
    const handleUserInput = (e) => {
      setUserInput((userInput) => ({
        ...userInput,
        [e.target.name]: e.target.value,
      }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      setLoading(true);
      axios
        .post("/api/v1/destination/create", userInput)
        .then((res) => {
          if (res?.data?.status === 200 || res?.data?.status === 201) {
            Swal.fire(
              "Success",
              "Destination Route has been created successfully",
              "success"
            ).then(()=>navigate(routes.DESTINATIONS))
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err?.response);
          if (err.response.data.status === 400 || err.response.status === 422) {
            setUserInput((userInput) => ({
              ...userInput,
              errors: err?.response?.data?.error_msg||err?.response?.data?.errors,
            }));
          } else {
            Swal.fire("Error", "User Account Creation Failed", "error");
          }
        })
        .finally(() => setLoading(false));
    };

  return (
    <div>
      <h1 className="h3 mb-3 text-center">Add New Destination</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex justify-content-center">
          <div className="w-75">
            <div className="row">
              <div className="card p-3">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={userInput?.name}
                      onChange={handleUserInput}
                      required
                    />
                     <small className="text-danger">
                      {userInput?.errors?.name}
                    </small>
                  </div>

                  <div className="form-group mt-2">
                    <label htmlFor="fare">Fare</label>
                    <input
                      type="number"
                      name="fare"
                      className="form-control"
                      onChange={handleUserInput}
                      value={userInput?.fare}
                      step="0.01"
                      required
                    />
                     <small className="text-danger">
                      {userInput?.errors?.fare}
                    </small>
                  </div>

                  <div className="form-group mt-2">
                    <label htmlFor="status">Status</label>
                    <select
                      name="status"
                      className="form-control"
                      value={userInput?.status}
                      onChange={handleUserInput}
                      required
                    >
                      <option value="">Select Status</option>
                      <option value="active">Active</option>
                      <option value="blocked">Blocked</option>
                    </select>
                    <small className="text-danger">
                      {userInput?.errors?.status}
                    </small>
                  </div>

                  <Button
                    cssClasses={"btn btn-primary mt-2"}
                    buttonText={"Create Destination"}
                    isloading={isloading}
                  />
                  <button
                    type="submit"
                    className="btn btn-secondary mt-2 ms-2"
                    onClick={() => navigate(routes.DESTINATIONS)}
                  >
                    Go Back
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDestination;
