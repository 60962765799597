import React, { useState } from "react";
import LoadingPage from "../../../components/customComponents/LoadingPage";
import Button from "../../../components/customComponents/Button";
import useGetFetch from "../../../components/customHooks/useGetFetch";
import axios from "axios";

const SmsLog = () => {
  const [loading, setLoading] = useState(false);
  const { data: references } = useGetFetch("/api/v1/reports/sms/references");

  const [userInput, setUserInput] = useState({
    sms_id: "",
    log:[]
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleLoadSms = (e) => {
    e.preventDefault();
    setLoading(true);
    axios.get(`/api/v1/reports/sms/${userInput?.sms_id}/report`)
    .then(res=>{
        setUserInput(userInput=>({
            ...userInput,log:res?.data?.data?.messages
        }))
    }).finally(()=>setLoading(false))
  };
  console.log(userInput?.log?.[0]);



  const convertDate=(date)=>{
    const formatteddate=new Date(date);
    const newdate= formatteddate.toLocaleDateString();
    const newtime=formatteddate.toLocaleTimeString();
    return newdate+' '+newtime
  }

  return (
    <div>
      <h1 className="h3 mb-3">SMS LOG</h1>
      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex">
          <div className="w-100">
            <div className="row">
              <div className="col-12d-flex">
                <div className="card flex-fill">
                  <div className="card-header">
                    <div className="d-flex flex-row justify-content-between">
                      <h5 className="card-title mb-0">BULK SMS LOG </h5>
                    </div>
                    <form onSubmit={handleLoadSms}>
                      <div className="row">
                        <div className="col-md-3 form-group mt-3">
                          <label htmlFor="department_id">Select Date:</label>
                          <select
                            className="form-control"
                            id="sms_id"
                            name="sms_id"
                            required
                            onChange={handleUserInput}
                            value={userInput?.sms_id}
                          >
                            <option
                              value=""
                              defaultValue={true}
                              selected
                              disabled
                            >
                              --Select Date--
                            </option>
                            {references?.data?.map((item) => (
                              <option value={item?.id}>
                                {convertDate(item?.created_at)}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-md-3 pt-2">
                          <div className="d-flex">
                            <Button
                              buttonText={"Load"}
                              cssClasses={"btn mt-4 btn-outline-success"}
                              isloading={loading}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <table className="table table-hover my-0">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th className="d-none d-md-table-cell">Phone </th>
                        <th className="d-none d-md-table-cell">Status</th>
                        <th className="d-none d-md-table-cell">Message</th>
                        <th className="d-none d-md-table-cell">Reference</th>
                      </tr>
                    </thead>
                    {loading ? (
                      <LoadingPage />
                    ) : (
                      <tbody>
                       {
                         userInput?.log?.[0]?.destinations?.map(item=><tr>
                            <td>{item?.reportDateTime}</td>
                            <td>{item?.to}</td>
                            <td className={item?.status?.label==='DS_DELIVERED'?'text-success':'text-danger'}>{item?.status?.label}</td>
                            <td>{userInput?.log?.[0]?.text}</td>
                            <td>{userInput?.log?.[0]?.reference}</td>
                         </tr>)
                       }
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmsLog;
