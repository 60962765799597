import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../components/strings/routes";
import axios from "axios";
import Swal from "sweetalert2";
import Button from "../../../components/customComponents/Button";

function AddUser() {
  const navigate = useNavigate();
  const [isloading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    role: "",
    status: "",
    errors: {},
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/api/v1/signup", userInput)
      .then((res) => {
        if (res?.data?.status === 200 || res?.data?.status === 201) {
          Swal.fire(
            "Success",
            "User account has been created successfully",
            "success"
          ).then(()=>navigate(routes.USERS))
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err?.response);
        if (err.response.data.status === 400 || err.response.status === 422) {
          setUserInput((userInput) => ({
            ...userInput,
            errors: err?.response?.data?.error_msg||err?.response?.data?.errors,
          }));
        } else {
          Swal.fire("Error", "User Account Creation Failed", "error");
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <h1 className="h3 mb-3 text-center">Add New User</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex justify-content-center">
          <div className="w-75">
            <div className="row">
              <div className="card p-3">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={userInput?.name}
                      onChange={handleUserInput}
                      required
                      maxLength={150}
                      placeholder="Enter name"
                    />
                    <small className="text-danger">
                      {userInput?.errors?.name}
                    </small>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={userInput?.email}
                      onChange={handleUserInput}
                      required
                      placeholder="Enter email"
                    />
                    <small className="text-danger">
                      {userInput?.errors?.email}
                    </small>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="password">Password:</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={userInput?.password}
                      onChange={handleUserInput}
                      required
                      placeholder="Enter password"
                    />
                    <small className="text-danger">
                      {userInput?.errors?.password}
                    </small>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="phone">Phone:</label>
                    <input
                      type="tel"
                      className="form-control"
                      name="phone"
                      value={userInput?.phone}
                      onChange={handleUserInput}
                      required
                      placeholder="Enter phone number"
                    />
                    <small className="text-danger">
                      {userInput?.errors?.phone}
                    </small>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="role">Role:</label>
                    <select
                      className="form-control"
                      name="role"
                      value={userInput?.role}
                      onChange={handleUserInput}
                      required
                    >
                      <option value="">Select Role</option>
                      <option value="admin">Admin</option>
                      <option value="conductor">Conductor</option>
                    </select>
                    <small className="text-danger">
                      {userInput?.errors?.role}
                    </small>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="role">Status:</label>
                    <select
                      className="form-control"
                      name="status"
                      value={userInput?.status}
                      onChange={handleUserInput}
                      required
                    >
                      <option value="">Select Status</option>
                      <option value="active">Active</option>
                      <option value="blocked">Blocked</option>
                    </select>
                    <small className="text-danger">
                      {userInput?.errors?.status}
                    </small>
                  </div>
                  <Button
                    cssClasses={"btn btn-primary mt-2"}
                    buttonText={"Save"}
                    isloading={isloading}
                  />
                  <button
                    type="submit"
                    className="btn btn-secondary mt-2 ms-2"
                    onClick={() => navigate(routes.USERS)}
                  >
                    Go Back
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
